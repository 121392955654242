.test__container{
    width: 750px;
}

.test__card{
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    padding: 1.5rem 2rem;
    margin-bottom: var(--mb-3);
}

.test__img{
    width: 60px;
    border-radius: 3rem;
    margin-bottom: var(--mb-1);
}

.test__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-25);
}


.test__description{
    font-size: var(--small-font-size);
}

.swiper-pagination-bullet{
    background-color: var(--text-color) !important;
}

.swiper-pagination-bullet-active{
    background-color: var(--title-color) !important;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .test__container{
        width: initial;
    }

    .test__card{
        padding: 1.25rem 1.5rem;
    }
}
    
