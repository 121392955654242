.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}


.footer__container{
    padding: 2rem 0 6rem;
    justify-content: center;
    align-items: center;
}

.footer__title,
.footer__link{
    color: var(--title-color);
}

.footer__title{
    text-align: center;
    margin-bottom: var(--mb-2);
}

.footer__link{
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}

.footer__social-link{
    background-color: var(--title-color);
    color: var(--container-color);
    font-size: 1.125rem;
    padding:0.4rem ;
    border-radius: 0.5rem;
    display: inline-flex;
    justify-self: center;
}


/* Add these styles to center the footer content and make .footer__link display flex */
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .footer__list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Adjust as needed */
  }
  
  .footer__list li {
    margin: 0 15px; /* Adjust as needed */
  }
  
  .footer__link {
    text-decoration: none;
    color: var(--title-color); /* Adjust the color as needed */
    font-size: 16px; /* Adjust the font size as needed */
  }
  
  /* Add these styles to center the social links */
  .footer__social {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Adjust as needed */
    margin-bottom: 20px;
  }
  
  .footer__social-link {
    margin: 0 15px; /* Adjust as needed */
    font-size: 24px; /* Adjust the font size as needed */
  }
  
  /* Add these styles to center the copyright text */
  .footer__copy {
    margin-top: 20px; /* Adjust as needed */
  }
  

@media screen and (max-width: 992px){
  .footer__social-link{
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
}
